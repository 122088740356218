
body {
  background-color: rgb(28,28,36);
  background-color: radial-gradient(circle, rgba(28,28,36,1) 24%, rgba(41,44,53,1) 71%);
}

.myBTN {
  background: rgb(243,156,87);
background: linear-gradient(90deg, rgba(243,156,87,1) 35%, rgba(231,97,85,1) 84%);
border-radius: 15px;
margin-bottom: 5px;
}

.apply {
  padding: 5px;
 border: 2px solid #ea8c27;
 border-bottom: none;
 
 border-radius: 10px;
 background-color: rgba(28,28,36,1);
 margin-top: -10px;
 

}
.menurow ul {
  list-style-type: none;
  margin: 0;
  padding: 10;
  overflow: hidden;
}
.menurow li {
  list-style-type: none;
  float: left;
  padding: 10px;
  text-decoration: none;
  
}

 ul li h3 {
  color: "#fff";
}

.table tr td {
  background-color: rgba(28,28,36,1);
  color: #fff;
}

.table tr td:hover {
  background-color: rgba(28,28,36,1);
  color: #ea8c27;
}

.herotext {
background: linear-gradient(275.07deg, #E75C55 5.95%, #F2A057 98.12%),
linear-gradient(0deg, #FFFFFF, #FFFFFF);
background-clip: text;
    color: transparent;
}


.cardslogan {
  background: linear-gradient(123.97deg, rgba(175, 179, 206, 0.33) -2.3%, rgba(98, 96, 118, 0.33) 102.79%),
  linear-gradient(0deg, rgba(217, 217, 217, 0.02), rgba(217, 217, 217, 0.02));
  margin-bottom: 5px;
}

.cardswallet {
  
  background: linear-gradient(275.07deg, rgba(231, 92, 85, 0.05) 5.95%, rgba(242, 160, 87, 0.05) 98.12%);
  margin-bottom: 5px;

}

.cardswallet2 {
  
  background: linear-gradient(275.07deg, rgba(70, 67, 67, 0.1) 5.95%, rgba(164, 164, 164, 0.1) 98.12%);
  margin-bottom: 5px;
}

.panelbar {
  background-color: #333;
  padding: 5px;
  color: #DEDEDE;
;
}

.bgcg {
  background: linear-gradient(275.07deg, rgba(70, 67, 67, 0.1) 5.95%, rgba(164, 164, 164, 0.1) 98.12%);
  border:1px solid #E75C55;
  color: "#fff";
  margin-bottom: 5px;
}

.bgcx {
  background: linear-gradient(275.07deg, rgba(70, 67, 67, 0.1) 5.95%, rgba(164, 164, 164, 0.1) 98.12%);
  border:1px solid #5a5a5a;
  color: "#fff";
  margin-bottom: 5px;
}

.activecard {
  background: linear-gradient(275.07deg, rgba(70, 67, 67, 0.1) 5.95%, rgba(164, 164, 164, 0.1) 98.12%);
  border:1px solid #5a5a5a;
  color: "#fff";
  margin-bottom: 5px;
}

.custom-checkbox {
  border: 1px solid orange; /* Set the border color to orange */
  border-radius: 10px; /* Add some border-radius for rounded corners */
  padding: 5px; /* Add padding for spacing */
}

/* Apply the custom style to the checkbox input */
.form-check-input {
  appearance: none; /* Remove default checkbox appearance */
  width: 20px; /* Adjust width to your preference */
  height: 20px; /* Adjust height to your preference */
  background-color: white; /* Set background color to white */
  border: none; /* Remove the default checkbox border */
  border-radius: 50%; /* Make it circular */
  outline: none; /* Remove the focus outline */
  cursor: pointer; /* Add a pointer cursor on hover */
  vertical-align: middle; /* Center vertically in the label */
}

/* Style the label text */
.form-check-label {
  vertical-align: middle; /* Center vertically in the label */
  margin-left: 5px; /* Add some spacing between the checkbox and label */
}

.myInput {
  border: 1px solid #5a5a5a;
  height: 60px;
  background-color:  #2b2e35
}
.myInput::placeholder {
  color: #fff; /* Change this to the desired color */
}

#dexscreener-embed{position:relative;height:100%;width:100%;padding-bottom:125%;}@media(min-width:1400px){#dexscreener-embed{padding-bottom:65%;}}#dexscreener-embed iframe{position:absolute;width:100%;height:100%;top:0;left:0;border:0;}









